<template>
  <div>
    <nav id="nav-drawer" :class="['nav-drawer', width > 992 ? 'active' : '']">
      <a @click="toggleNav" class="w-100 row nav-toggle btn btn-tertiary" v-if="width < 992">
        <svg-icon icon="cross" classes="close" fillColor="#fba025" strokeColor="#000"></svg-icon>
        <svg-icon icon="dots" classes="open" fillColor="#fba025" strokeColor="#000"></svg-icon>
      </a>
      <a href="/" title="Home page" class="d-flex justify-content-center">
        <img :src="logo" alt="Logo" class="logo p-2 w-sm-100" />
      </a>
      <ul class="nav-ul mb-2">
        <li class="nav-li" 
          v-for="page in pages"
          :key="page.id"
          >
          <a :href="'/' + page.slug" v-bind:title="page.title" class="w-100 row">
            <!--svg-icon icon="navigate" classes="pt-0 pb-0 pl-1 pl-sm-0 pl-lg-1 pr-1 col-2" fillColor="#fba025" strokeColor="#fff"></svg-icon-->
            <span class="col-10 nav-li__text tooltip text-muted" v-if="page.status !== `published`">{{ page.title }} [{{page.status}}]</span>
            <span class="col-10 nav-li__text tooltip" v-else>{{ page.title }}</span>
          </a>
        </li>
        <li class="nav-li">
          <a href="/posts" title="Blog" class="w-100 row">
            <!--svg-icon icon="mail" classes="pt-0 pb-0 pl-1 pl-sm-0 pl-lg-1 pr-1 col-2" fillColor="#555" strokeColor="#555"></svg-icon-->
            <span class="col-10 nav-li__text tooltip">Blog</span>
          </a>
        </li>
      </ul>
      <span class="spacer"></span>
      <ul class="nav-ul mb-2">
        <li class="nav-li nav-li__admin">
          <a href="/users" title="Users" class="w-100 row" v-if="isadmin">
            <svg-icon icon="users" classes="pt-0 pb-0 pl-1 pl-sm-0 pl-lg-1 pr-1 col-2" fillColor="#555" strokeColor="#555"></svg-icon>
            <span class="col-10 nav-li__text tooltip">Users</span>
          </a>
        </li>
        <li class="nav-li nav-li__admin">
          <a href="/pages" title="Pages" class="w-100 row" v-if="canedit">
            <svg-icon icon="document" classes="pt-0 pb-0 pl-1 pl-sm-0 pl-lg-1 pr-1 col-2" fillColor="#555" strokeColor="#555"></svg-icon>
            <span class="col-10 nav-li__text tooltip">Pages</span>
          </a>
        </li>
        <li class="nav-li nav-li__admin">
          <a href="/sections" title="Posts" class="w-100 row" v-if="canedit">
            <svg-icon icon="document" classes="pt-0 pb-0 pl-1 pl-sm-0 pl-lg-1 pr-1 col-2" fillColor="#555" strokeColor="#555"></svg-icon>
            <span class="col-10 nav-li__text tooltip">Sections</span>
          </a>
        </li>
      </ul>
      <small class="w-100 text-center">&copy; kaybrird {{ new Date().getFullYear() }}</small>
    </nav>
  </div>
</template>
 <!--  -->
<script>

import { nextTick, onMounted, ref } from "vue"

export default {
  name: 'nav-drawer',
  props: {
    pages: Array,
    user: {
      type: String,
      default: null
    },
    canedit: {
      type: Boolean,
      default: false
    },
    isadmin: {
      type: Boolean,
      default: false
    },
    logo: String
  },
  setup(props) {
    const pages = props.pages
    const user = JSON.parse(props.user)
    const logo = props.logo
    const width = ref(window.innerWidth)

    const toggleNav = () => {
      let classes = document.getElementById('nav-drawer').classList

      if (classes.contains('active')) {
        classes.remove('active')
      } else {
        classes.add('active')
      }
    }

    const handleResize = () => {
      width.value = window.innerWidth
    }

    onMounted(() => {
      nextTick(() => {
        handleResize()
      })
      window.addEventListener('resize', handleResize)
    })

    return {
      toggleNav,
      pages,
      user,
      logo,
      width
    }
  }
}
</script>

<style lang="scss" scoped>
#nav-drawer {
  z-index: 999;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.2);

  transition: all 0.3s ease-in-out;

  .logo {
    width: 92px;
  }

  &.nav-drawer.active {
    width: 232px;

    .logo { 
      width: 200px;
    }
  }

  &.nav-drawer .nav-ul .nav-li{
    &:hover {
      border-right: 4px solid #fba025;

      svg {
        fill: #fba025;
        stroke: #fba025;
      }
    }
  }

  .nav-toggle {
    position: absolute;
    top: 5px;
    right: -40px;
    z-index: 9999;
    border: 0;
    border-radius: 0;
    box-shadow: 7px 0px 7px rgba(0, 0, 0, 0.2);
    height: 40px !important;
    width: 40px !important;
    padding: 7px 0 0 9px;
    background: white;

    .close {
      display: none;
    }
    .open {
      display: block;
    }
  }

  @media (max-width: 992px) {
    &.nav-drawer {
      &.active {
        .nav-ul .nav-li .nav-li__text {
          display: block;
        }
        
        .btn-primary-text {
          display: block;
        }

        .nav-toggle {
          .open {
            display: none;
          }

          .close {
            display: block;
          }
        }
      }
    }

    &.nav-drawer:not(.active) {
      .btn.btn-primary__icon {
        padding: 11px 12px 10px;
      }

      a > .tooltip {
        min-width: 120px;
        width: auto;
        background-color: #fba025;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        display: none;
      
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
      }

      a:hover > .tooltip {
        display: block !important;
        left: 105%;

        &::after{
          content: " ";
          position: absolute;
          top: 50%;
          right: 100%; /* To the left of the tooltip */
          margin-top: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent #fba025 transparent transparent;
        }
      }
    }
  }

  @media (max-width: 580px) {
    &.nav-drawer:not(.active) {
      left: -58px;

      .nav-toggle {
        right: -40px;
      }
    }
  }
}
</style>
